var gMinLaonamount;
var gMaxLaonamount;
var gPupilCount;
var gSliderInterval;
var gXPercentageRate;
var gAnnualPercentageRate;
var gTransactionPercentageRate;
var gTransactionPercentageRateText;
var gAnnualFees;
var gPaymentVariant;
var gCreditAmount;
var gRegion = 'en-GB';
var aprValue = '0';
var transactionPercentage = '0.00';
var monthlyRepaymentsOf = ' monthly repayments of';

// Method to initialize method and event binding.
$(document).ready(function () {
    $("#txtAnnualFeesAmount").css('text-align', 'right');
    $("#txtAnnualFeesAmount").autoNumeric({ mNum: 8, mDec: 2 });

    // Bind txtAnnualFeesAmount method on focus event of annual fee amount textbox.
    $("#txtAnnualFeesAmount").bind("focus", function (event) {
        var annualFeesAmount = parseFloat(stripCurrency("", $("#txtAnnualFeesAmount").val()));
        if (annualFeesAmount == 0) {
            $("#txtAnnualFeesAmount").val("");
        }
        $("#postLoadingContent").hide();
        $('#adjust_quote').hide();
        $("#btnCalculatePayment").show();
    });

    // Bind txtAnnualFeesAmount method on blur event of annual fee amount textbox.
    $("#txtAnnualFeesAmount").bind("blur", function () {
        //fnSetSliderValue(); TODO
        fnResetCalulatedValue();
        if (parseFloat($("#txtAnnualFeesAmount").val()) > 0 && parseFloat($('input[name="term_selection"]:checked').val()) > 0)
            $('#btnCalculatePayment').prop('disabled', false);
        else
            $('#btnCalculatePayment').prop('disabled', true);

        $("#btnCalculatePayment").focus();
    });

    // Restricted enter key to refresh page.
    $('#txtAnnualFeesAmount').keypress(function (event) {
        if (event.keyCode == 13) {
            //fnSetSliderValue(); TODO
            event.preventDefault();
        }
        else {
            fnResetCalulatedValue();
        }
    });
    // Adjust quote click event 
    $('#adjust_quote').click(function () {
        $("#btnCalculatePayment").show();
        $('#adjust_quote').hide();
        $("#loadingMessage").hide();
        $("#postLoadingContent").hide();
    });

    // Bind calulate method on click event of btnCalculatePayemnt button
    $('input[name="btnCalculatePayment"]').click(function () {
        $("#adjust_quote").show();
        $("#btnCalculatePayment").hide();
        $("#loadingMessage").show();

        var errorText = $('#hfPleaseSelectTerms').val();
        var annualFeeAmountErrorText = $('#hfPleaseEnterExpectedSchoolFees').val();

        // Get loan payment and credit amount calculated only if any term is selected.
        var selectedId = $("input[name='term_selection']:checked").val();

        // Get annual fees amount amount
        var annualFeesAmount = parseFloat(stripCurrency("", $("#txtAnnualFeesAmount").val()));

        if (!(selectedId == "" || selectedId == undefined)) {
            if (annualFeesAmount > 0) {
                fnCalulateLoanPayment();
                fnCalulateCreditAmount();
                $("#sliderContainer").next('span').html('');
            }
            else {
                $("#sliderContainer").next('span').html(annualFeeAmountErrorText);
            }
        } else {
            fnResetCalulatedValue();
            $("#ProductContainer").next('span').html(errorText);
            if (annualFeesAmount > 0) {
                $("#sliderContainer").next('span').html('');
            }
            else {
                $("#sliderContainer").next('span').html(annualFeeAmountErrorText);
            }
        }
        $("#postLoadingContent").show();
    });

    // Set default value of slider.
    ///var defaultSliderValue = parseFloat(gCreditAmount) / 2;

    // set slider properties
    fnSetSliderDefaultConfiguration();

    //var sliderChangeValue = $("#paymentCalculatorSlider").slider("value");
    /*    $("#txtAnnualFeesAmount").val("0.00").formatCurrency({ region: gRegion });*/
    $("#txtAnnualFeesAmount").val("0.00");

    fnResetCalulatedValue();

    $('input[type=radio][name=term_selection]').change(function () {
        $("#postLoadingContent").hide();
        $('#adjust_quote').hide();
        $("#btnCalculatePayment").show();
        if (!(this.value == "" || this.value == undefined)) {
            $("#ProductContainer").next('span').html('');
            fnGetProductParameters(this.value);
            if (parseFloat($("#txtAnnualFeesAmount").val()) > 0)
                $('#btnCalculatePayment').prop('disabled', false);
            else
                $('#btnCalculatePayment').prop('disabled', true);
        } else {
            //var errorText = $('#hfPleaseSelectTerms').val(); 
            //fnSetSliderDefaultConfiguration();
            fnResetCalulatedValue();
            fnResetProductParameter();
            if ($('#lblTransactionPercentageRateFor').length > 0) {
                $('#lblTransactionPercentageRateFor').text($('#hfTransactionFeeText').val().replace('{0}', '(0.00%)'));
                $('#lblTransactionPercentageRateModeFor').text('0.00%');
            }
            //$("#txtAnnualFeesAmount").val("0.00").formatCurrency({ region: gRegion });
            //fnSetSliderValue();
            //$("#ProductId").next('span').html(errorText);
        }
        // Remove the errors for expected annual school fees
        $("#sliderContainer").next('span').html('');
        $("#txtAnnualFeesAmount").focus();
    });

    fnResetProductParameter();
});

// Reset or empty all product parameter values. 
function fnResetProductParameter() {
    $('#lblPaymentModeFor').html("");
    $("#lblProductStartDateFor").html("");
    $("#lblPaymentVariantFor").html("");

    if ($('#lblAnnualPercentageRateFor').length > 0) {
        $('#lblAnnualPercentageRateFor').html($('#hfAnnualPercentageRateValue').val().replace('{0}', '0%'));
    }

    if ($('#lblTransactionPercentageRateFor').length > 0) {
        $('#lblTransactionPercentageRateFor').text($('#hfTransactionFeeText').val().replace('{0}', '(0.00%)'));
        $('#lblTransactionPercentageRateModeFor').text('0.00%');
    }
}

// Get the product parameter(s) value based on selected term
function fnGetProductParameters(selectedId) {

    // Get product parameter(s) value only if any term is selected.
    if (selectedId == "" || selectedId == undefined) {
        fnResetProductParameter();
        //$("#txtAnnualFeesAmount").val("0.00").formatCurrency({ region: gRegion });
        $("#txtAnnualFeesAmount").val("0.00");
        fnResetCalulatedValue();
        fnSetSliderDefaultConfiguration();
    }
    else {
        var obj = {
            productId: selectedId,
            "__RequestVerificationToken": GetToken()
        };

        AjaxCommunication.CreateRequest(
            this.window,
            'POST',
            "/Application/GetProductParameters",
            '',
            obj,
            function (result) {
                fnSetTermWiseParameters(result);
            },
            null
        );
        return false;
    }
}

// Set to default configuration for payment slider
function fnSetSliderDefaultConfiguration() {

    var maxLoanAmt = parseFloat($("#hfMaxLoanAmount").val());
    // set slider properties
    var paymentCalSlider = $("#paymentCalculatorSlider").slider({
        range: "min",
        value: 0,
        min: 0,
        max: maxLoanAmt,
        step: 0,
        slide: function (event, ui) {
            $("#txtAnnualFeesAmount").val(ui.value).formatCurrency({ region: gRegion });

            // Remove the errors for expected annual school fees
            $("#sliderContainer").next('span').html('');
            fnResetCalulatedValue();
        }
    });

    // reset the slider control with new configuration value.
    paymentCalSlider.slider("refresh");
}

// Set all product parameters values based on selected term
function fnSetTermWiseParameters(result) {
    if (result != null && result.Result.Status == true && result.Result.Data != null) {
        //-------------------------------------------------------------------------
        var data = result.Result.Data;
        $('#hfIntervalAmount').val(data.IntervalAmount);
        $('#hfMinLoanAmount').val(data.MinLoanAmount);
        $('#hfMaxLoanAmount').val(data.MaxLoanAmount);
        $('#hfPupilCount').val(data.PupilCount);
        $('#hfXPercentIncreaseDecrease').val(data.XPercentIncreaseDecrease);
        $('#hfAnnualPercentageRate').val(GetPercentileValueFromString(data.AnnualPercentageRate));
        $('#hfTransactionPercentageRate').val(GetPercentileValueFromString(data.TransactionPercentageRate));
        $('#hfPaymentVariant').val(data.PaymentVariant);
        gTransactionPercentageRateText = data.TransactionPercentageRate.indexOf('%') != -1 ? data.TransactionPercentageRate.substr(0, data.TransactionPercentageRate.length - 1) : data.TransactionPercentageRate;

        // Set all global variables from business parameter hidden fields
        gMinLaonamount = parseFloat($("#hfMinLoanAmount").val());
        gMaxLaonamount = parseFloat($("#hfMaxLoanAmount").val());
        gPupilCount = parseInt($("#hfPupilCount").val());
        gSliderInterval = parseFloat($("#hfIntervalAmount").val());
        gXPercentageRate = parseFloat($("#hfXPercentIncreaseDecrease").val());
        gAnnualPercentageRate = parseFloat($("#hfAnnualPercentageRate").val());
        gTransactionPercentageRate = parseFloat($("#hfTransactionPercentageRate").val());
        gAnnualFees = parseFloat($("#hfAnnualFees").val());
        gPaymentVariant = parseInt($("#hfPaymentVariant").val());

        $("#txtAnnualFeesAmount").autoNumeric({ mNum: 8, mDec: 2 });

        if (gTransactionPercentageRate > 0 && $('#lblTransactionPercentageRateFor').length > 0) {
            $('#lblTransactionPercentageRateFor').text($('#hfTransactionFeeText').val().replace('{0}', data.TransactionPercentageRate));
            transactionPercentage = data.TransactionPercentageRate.replace('%', '');
        }

        if (gMaxLaonamount > 0 && gPupilCount > 0 && gXPercentageRate >= 0) {
            fnCalulateCreditAmount();

            //var totalCreditAmount = (gMaxLaonamount * gPupilCount ) + gXPercentageRate;
            //$("#lblTotalCreditAmount").html(totalCreditAmount).formatCurrency({ region: gRegion });
        }


        // Set default value of slider.
        var defaultSliderValue = parseFloat(gCreditAmount) / 2;

        // Set slider min and max value to zero if no value(s) populated from db.
        // This implementation is just to avoid page error while slide. 
        gMinLaonamount = isNaN(gMinLaonamount) ? 0 : gMinLaonamount;
        gCreditAmount = gCreditAmount == undefined ? 0 : gCreditAmount;

        // set slider properties
        var paymentCalSlider = $("#paymentCalculatorSlider").slider({
            range: "min",
            value: 0,
            min: gMinLaonamount,
            max: gCreditAmount,
            step: gSliderInterval,
            slide: function (event, ui) {
                $("#txtAnnualFeesAmount").val(ui.value).formatCurrency({ region: gRegion });

                // Remove the errors for expected annual school fees
                $("#sliderContainer").next('span').html('');
                fnResetCalulatedValue();
            }
        });
        //fnSetSliderValue();TODO
        //        // reset the slider control with new configuration value.
        //        paymentCalSlider.slider("refresh");

        //        //var sliderChangeValue = $("#paymentCalculatorSlider").slider("value");
        //        $("#txtAnnualFeesAmount").val("0.00").formatCurrency({ region: gRegion });
        fnResetCalulatedValue();

        $('#lblPaymentModeFor').html(data.PaymentMode);
        $('#lblPaymentVariantFor').html(data.PaymentVariant);
        $('#lblProductStartDateFor').html(GetDateOrdinalSuperScript(data.ProductStartDateOrdinal) + '&nbsp;' + data.ProductStartDateMonthName);
        var apr = data.AnnualPercentageRate.indexOf('%') != -1 ? data.AnnualPercentageRate.substr(0, data.AnnualPercentageRate.length - 1) : data.AnnualPercentageRate;
        $('#lblAnnualPercentageRateFor').html($('#hfAnnualPercentageRateValue').val().replace('{0}', apr));
        apr = Number(apr).toFixed(2);
        aprValue = apr.replace('%', '') + '%';

        var monthlypayText = $('#lblMonthlyPaymentsFor').text().trim();
        if (monthlypayText.match(/(\d+)/g) != null && monthlypayText.match(/(\d+)/g) != undefined) {
            monthlypayText = monthlypayText.replace(monthlypayText.match(/(\d+)/g)[0], '')
        }
        $('#lblMonthlyPaymentsFor').text('0 ' + monthlypayText);
        $('#lblRepaidOverPaymentVariantFor').html('0' + '&nbsp;' + monthlyRepaymentsOf);
    }
}

// Reset all payment calulated value
function fnResetCalulatedValue() {

    $("#lblPaymentVariantFee").html("0.00").formatCurrency({ region: gRegion });
    $("#lblCalulatedLoanAmount").html("0.00").formatCurrency({ region: gRegion });
    $("#lblTotalAmountPayable").html("0.00").formatCurrency({ region: gRegion });
    $("#lblTotalCreditAmount").html("0.00").formatCurrency({ region: gRegion });
    $('#lblRepaidOverPaymentVariantFor').html('0' + '&nbsp;' + monthlyRepaymentsOf);
    $('#lblAnnualPercentageModeFor').html('0%');//
    var monthlypayText = $('#lblMonthlyPaymentsFor').text().trim();
    if (monthlypayText.match(/(\d+)/g) != null && monthlypayText.match(/(\d+)/g) != undefined) {
        monthlypayText = monthlypayText.replace(monthlypayText.match(/(\d+)/g)[0], '')
    }
    $('#lblMonthlyPaymentsFor').text('0 ' + monthlypayText);
    $('#lblTransactionPercentageRateModeFor').html('0.00%');
    // If Flexifee product then lblAnnualPercentageAmount is rendered or visible and set amount 
    if ($("#lblAnnualPercentageAmount").length > 0) {
        $("#lblAnnualPercentageAmount").html(parseFloat("0.00")).formatCurrency({ region: gRegion });
    }
}

// Method to set the slider value based on entered annual fee amount.
function fnSetSliderValue() {
    var annualFeeAmount = $("#txtAnnualFeesAmount").val();
    $("#txtAnnualFeesAmount").formatCurrency({ region: gRegion });
    var sliderValue = parseFloat(stripCurrency("", annualFeeAmount));

    // set the slider cursor position based on annual fee anmount. 
    if (sliderValue != null && sliderValue != undefined) {
        sliderValue = sliderValue <= 0 ? 0 : sliderValue;

        // Set slider value based on modified annual fee amount textbox
        $("#paymentCalculatorSlider").slider("option", "value", sliderValue);
        $("#txtAnnualFeesAmount").val(sliderValue.toString()).formatCurrency({ region: gRegion });
    }
}

// Method to set total credit amount.
function fnCalulateCreditAmount() {

    //var annualFeeAmount = parseFloat(stripCurrency("", $("#txtAnnualFeesAmount").val()));
    var annualFeeAmount = parseFloat(stripCurrency("", $("#lblTotalAmountPayable").html()));
    //    var totalCreditAmount = (gMaxLaonamount * gPupilCount) * (gXPercentageRate / 100 + 1);
    //    gCreditAmount = totalCreditAmount;

    gCreditAmount = gMaxLaonamount;

    /* If entered expected annual fee amount is greater than default credit amount then
        update credit amount with max loan amount.
    */
    //    while (annualFeeAmount > totalCreditAmount) {
    //        totalCreditAmount = totalCreditAmount + gMaxLaonamount;
    //    };

    var creditAmount = annualFeeAmount + (gXPercentageRate * annualFeeAmount);
    creditAmount = isNaN(creditAmount) ? 0 : creditAmount;
    $("#lblTotalCreditAmount").html(creditAmount).formatCurrency({ region: gRegion });
    $("#loadingMessage").hide();
}

// Method to calulate Loan payment value
function fnCalulateLoanPayment() {

    var annualFeesAmount = parseFloat(stripCurrency("", $("#txtAnnualFeesAmount").val()));
    var term = $("input[name='term_selection']:checked").attr('id').substring(15);
    var selectedProductId = $("input[name='term_selection']:checked").val();
    if (term === "autumn") {
        if (selectedProductId === "60") {
            annualFeesAmount = annualFeesAmount * 2
        }
        else {
            annualFeesAmount = annualFeesAmount * 3
        }
    }
    else if (term === "spring") {
        if (selectedProductId === "59") {
            annualFeesAmount = annualFeesAmount * 1
        }
        else {
            annualFeesAmount = annualFeesAmount * 2
        }
    }

    if (annualFeesAmount != null && annualFeesAmount >= 0) {
        var annualPercentageAmount = annualFeesAmount * parseFloat(gTransactionPercentageRate);
        var paymentVariant = parseFloat(gPaymentVariant);
        var totalAmountPayable = annualFeesAmount + annualPercentageAmount;

        $("#lblCalulatedLoanAmount").html(annualFeesAmount).formatCurrency({ region: gRegion });

        // If Flexifee product then lblAnnualPercentageAmount is rendered or visible and set amount 
        if ($("#lblAnnualPercentageAmount").length > 0) {
            $("#lblAnnualPercentageAmount").html(annualPercentageAmount).formatCurrency({ region: gRegion });
        }

        $("#lblTotalAmountPayable").html(totalAmountPayable).formatCurrency({ region: gRegion });

        // Set paymentVariantFee to zero if  paymentVariantFee value is NaN or Infinity
        var paymentVariantFee = totalAmountPayable / paymentVariant;
        paymentVariantFee = isNaN(paymentVariantFee) || !isFinite(paymentVariantFee) ? 0 : paymentVariantFee;
        $("#lblPaymentVariantFee").html(paymentVariantFee).formatCurrency({ region: gRegion });

        var monthlypayText = $('#lblMonthlyPaymentsFor').text().trim();
        if (monthlypayText.match(/(\d+)/g) != null && monthlypayText.match(/(\d+)/g) != undefined) {
            monthlypayText = monthlypayText.replace(monthlypayText.match(/(\d+)/g)[0], '')
        }
        $('#lblMonthlyPaymentsFor').text(gPaymentVariant + ' ' + monthlypayText);
        $('#lblRepaidOverPaymentVariantFor').html(paymentVariant + '&nbsp;' + monthlyRepaymentsOf);
        $('#lblAnnualPercentageModeFor').html(aprValue);
        $('#lblTransactionPercentageRateModeFor').html(Number(gTransactionPercentageRateText).toFixed(2) + '%');
    }
}

// Method to strip currency from currency format value.
function stripCurrency(ii, value, options) { // public function that stripes the format and converts decimal seperator to a period
    if (value == null || value == undefined)
        return 0;
    var opts = $.extend({}, $.fn.autoNumeric.defaults, options);
    var io = $.metadata ? $.extend({}, opts) : opts;
    io.mDec = isNaN(io.mDec * 1) ? $('#' + io.mDec).val() * 1 : io.mDec * 1; // decimal places
    var iv = value;
    iv = iv.replace(io.aSign, '').replace('\u00A0', '');
    var reg = new RegExp('[^' + '\\-' + io.aNum + io.aDec + ']', 'gi'); // regular expreession constructor
    iv = iv.replace(reg, ''); //deletes all characters that are not permitted in this field
    var nSign = '';
    if (iv.charAt(0) == '-') { //Checks if the iv (input Value)is a negative value
        nSign = (iv * 1 === 0) ? '' : '-'; //determines if the value is zero - if zero no negative sign
        iv = iv.replace('-', ''); // removes the negative sign will be added back later if required                  
    }
    iv = iv.replace(io.aDec, '.');
    if (iv * 1 > 0) {
        while (iv.substr(0, 1) == '0' && iv.length > 1) {
            iv = iv.substr(1, 9999);
        }
    }
    iv = (iv.lastIndexOf('.') === 0) ? ('0' + iv) : iv;
    iv = (iv * 1 === 0) ? '0' : iv;
    return nSign + iv;
};

function GetToken() {
    var tokenValue = "";

    tokenWindow = this && typeof this === typeof window ? this : window;
    var tokenName = "__RequestVerificationToken";
    var inputElements = tokenWindow.document.getElementsByTagName("input");
    for (var i = 0; i < inputElements.length; i++) {
        var inputElement = inputElements[i];
        if (inputElement.type === "hidden" && inputElement.name === tokenName) {
            tokenValue = $(inputElement).val();
            break;
        }
    }

    return tokenValue;
}
